/* Light Mode */
body {
  background-color: #FFFFFF;
}

main {
  background-color: #FFFFFF;
}

header{
  border-color: #CCCCCC;
  background-color: #FFFFFF;
}

.card {
  background-color: #FFFFFF;
  min-height: 230px;
  transition: all 0.2s;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.card.inactive {
  background-color: #EEEEEE;
}

.card-note {
  width: 96%;
  height: 96%;
}



.card-group {
  background-color: #EEEEEE;
}

.card-group .info-device-model {
  display: none;
}

.card-actions a {
  min-width: 24px;
  text-align: center;
}

.card-group .card-actions a {
  width: 24px;
  text-align: center;
}

.card-group .card-actions a > span {
  display: none;
}

.text-name {
  min-height: 40px;
  color: #1F243B;
}

.text-link {
  background-color: #F5F4F8;
  padding: 5px 5px;
  color: #666666;
  border-radius: 10%;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.device-icon {
  width: 60px;
  height: 60px;
  position: relative;
}

.device-icon i {
  position: absolute;
  width: 100%;
  min-width: 20px;
  min-height: 20px;
}

.text-link:hover {
  background-color: #EEE;
}

.device-card p {
  margin-bottom: 6px;
}

.text-info {
  color: #606479;
}

/* Dark Mode */
body.dark {
  background-color: #222222;
  color: #f2f2f2;
}

body.dark header {
  border-color: #666666;
}

body.dark .card {
  background-color: #121212;
  color: #f2f2f2;
}

/* Components */

.env-card {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  transition: all 0.3s;
}

.env-card:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

/* Utilities */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-icon {
  font-size: 22px;
  font-weight: normal;
  line-height: 0.6rem;
  margin: 0 auto;
}

.spinner {
  animation: spin-animation 0.6s infinite;
  animation-timing-function: linear;
  display: inline-block;
  
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* Responsive */
@media (max-width: 768px) {
  .card-wrapper {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1280px) {
  .card-wrapper {
    grid-template-columns: 1fr !important;
  }
}

@media (min-width: 1280px) {
  .card-group .device-icon {
    width: 40px;
    height: 40px;
  }
  
  .card-group .device-icon i {
    min-width: 30px;
    min-height: 30px;
  }

  .card-group .text-name {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 1.3rem;
  }

  .card-group .card-actions {
    top: 0.5rem;
  }

  .card-group .text-device-platform {
    font-size: 0.7rem;
    width: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-group .text-now-playing {
    font-size: 0.8rem;
    margin-left: -2rem;
  }

}

@media (min-width: 1792px) {

  .card .text-3xl-visible {
    display: inline-block !important;
  }

  .card-group .device-icon i {
    min-width: 45px;
    min-height: 45px;
  }

  .card-group .text-3xl-visible {
    display: none !important;
  }

  .card-group .text-name {
    margin-bottom: 0;
    line-height: 1rem;
  }

  .card-group .text-device-platform {
    font-size: 0.8rem;
    width: auto;
  }

  .card-group .text-now-playing {
    font-size: 0.9rem;
    margin-left: auto;
  }
  
}